import banner from 'assets/banner400.png';
import { Card } from 'primereact/card';
import { useEffect, useRef, useState } from 'react';
import { IBatch, IEvent, ITicket } from 'types';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog'; // To use <ConfirmDialog> tag
import { confirmDialog } from 'primereact/confirmdialog'; // To use confirmDialog method
import styles from './Profile.module.scss';
import './Profile.css';
import axios from 'api';

export default function Profile() {

	const [event, setEvent] = useState<IEvent>();
	const [batch, setBatch] = useState<IBatch>();
	const [ticket, setTicket] = useState<ITicket>();
	const [needAccommodation, setNeedAccommodation] = useState<boolean>(false);
	const [showAccommodation, setShowAccommodation] = useState<boolean>(false);
	const toast = useRef<Toast>(null);

	useEffect(() => {
		
		axios.get('event/current')
			.then(res => {
				setEvent(res.data.data);
				setBatch(res.data.data.current_batch);
				setTicket(res.data.data.ticket);
				setNeedAccommodation(res.data.data.user.need_accommodation);
				setShowAccommodation(res.data.data.user.show_need_accommodation);
			})
			.catch(err => console.log('falha na requisição'));
	}, []);

	// Comentario
	const approvePurchase = () => {
		axios.post('event/registerTicket', {
			id_batch: batch?.id	
		})
			.then(res => {
				toast.current?.show({severity: 'success', summary: 'Sucesso', detail: 'Inscrição realizada, aguardando aprovação...'});
			})
			.catch(err => {
				toast.current?.show({severity: 'error', summary: 'Falha', detail: err.response.data.messages.replace('Already in use', 'Já cadastrado')});
			});
	};

	const updateNeedAccommodation = (need : boolean) => {
		axios.patch('user/updateNeedAccommodation', {
			need
		})
			.then(res => {
				toast.current?.show({severity: 'success', summary: 'Sucesso', detail: 'Status do Alojamento atualizado...'});
				setNeedAccommodation(res.data.data.need_accommodation);
			})
			.catch(err => {
				toast.current?.show({severity: 'error', summary: 'Falha', detail: 'Falha ao atualizar Status do Alojamento'});
			});
	};

	const template = <div>
		Observações importantes:
		<ul>
			<li> 
				Em caso de desistência com até 60 dias do evento, com justa causa, será devolvido 75% do valor da inscrição. 
				Após essa data, não haverá reembolso. 
			</li>
			<br />
			<li> Em caso de adiamento do evento, por algum impedimento na sua realização, a inscrição será adiada para o próximo evento de organização da LINE </li>
			<br />
			<br />
			<li> 
				A atlética organizadora preza pelo bem estar dos participantes do evento, com tolerância ZERO para 
				agressões físicas, qualquer manifestação de racismo, homofobia e todo tipo de preconceito, 
				sendo o atuante punido com expulsão do evento e a atlética pela qual o mesmo está inscrito passível de punição
			</li>
			<br />
			<li> Os participantes precisam ter 18 anos completos até o dia do evento, caso haja tentativa de inscrição por menores de idade, a mesma será cancelada. </li>
			<li> Ao confirmar sua inscrição você está de acordo com os termos acima e com todas as decisões da LINE. </li>
		</ul>
	</div>;

	const confirm = () => {
		confirmDialog({
			message: template,
			header: 'Confirmação',
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: 'Confirmar Inscrição',
			rejectLabel: 'Cancelar',
			accept: () => approvePurchase(),
			style: {maxWidth: '1350px'}
		});
	};
	

	const footer = <span>
		{!ticket && <Button label='Finalize sua inscrição' type='button' icon='pi pi-dollar' style={{marginRight: '.25em'}} onClick={confirm}/>}
	</span>;

	const footer_accommodation = <span>
		<Button label={'Vou precisar de alojamento'} type='button' className='p-button-success mb-3' icon='pi pi-check' style={{marginRight: '.25em'}} onClick={() => updateNeedAccommodation(true)}/>
		<Button label={'Não necessito de alojamento'} type='button' className='p-button-warning' icon='pi pi-times' style={{marginRight: '.25em'}} onClick={() => updateNeedAccommodation(false)}/>
	</span>;


	return (
		<section>
			<ConfirmDialog />
			<Toast ref={toast} style={{opacity: 1}}></Toast>
			{event && 
				<Card title={event.name} className='my-3' header={
					<div style={{ maxHeight: '850px', overflow: 'hidden' }}>
						<img src={banner} className={styles.banner} />
					</div>
				}>

					{(batch && !ticket) && <Card className="sm:w-12 md:w-5 mr-4 my-3" title={batch.description} footer={footer}>
						<span>O valor deverá ser pago diretamente à atlética</span>	
					</Card>}
					{(!batch && !ticket) && <span>Não há lotes disponíveis no momento</span>}
					{ticket && <Card className="sm:w-12 md:w-5 mr-4 my-3" title='Inscrição Realizada'>
						<span> Status: {ticket.paid ? 'Aprovada' : 'Aguardando Pagamento'}</span>
					</Card>}
					{showAccommodation && <Card className="sm:w-12 md:w-5 my-3" title='Vai precisar de Alojamento?' footer={footer_accommodation}>
						<span> Minha Resposta: <b>{needAccommodation ? 'Sim, vou precisar de alojamento' : 'Não necessito de alojamento'} </b></span>
					</Card>}
				</Card>
			}
		</section>
	);
}