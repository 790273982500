import axios from 'api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';

export default function Batch() {

	const [batches, setBatches] = useState([]);
	const [redirect, setRedirect] = useState(false);
	const toast = useRef<Toast>(null);

	useEffect(() => {
		searchBatches();
	}, []);

	const searchBatches = () => {
		axios.get('batch')
			.then(res => {
				setBatches(res.data.data);
			});
	};

	const remove = (row: any) => {
		axios.delete(`batch/${row.id}`)
			.then(res => {
				toast.current?.show({severity: 'success', summary: 'Sucesso', detail: 'Lote Removido'});
				searchBatches();
			})
			.catch(err => {
				toast.current?.show({severity: 'error', summary: 'Falha', detail: err.response.data.messages});
			});
	};

	const redirectNewBatch = () => {
		setRedirect(true);
	};

	if (redirect) return <Navigate to='new' />;


	const detailButton = (rowData: any) => {
		return (
			<Button label='Excluir' icon='pi pi-trash' className="p-button-danger p-1" type='button' onClick={() => remove(rowData)} />
		);
	};

	return (
		<section className='my-3'>
			<Toast ref={toast} style={{opacity: 1}}></Toast>

			<Button label='Cadastrar' icon='pi pi-plus' style={{marginRight: '.25em'}} onClick={redirectNewBatch} />


			<DataTable value={batches} stripedRows paginator responsiveLayout='scroll' 
				paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
				currentPageReportTemplate="Mostrando {first} ~ {last} de {totalRecords}" rows={10}
				emptyMessage="Nenhum Resultado Encontrado" className='my-3'>
				<Column field="description" header="Descrição"></Column>
				<Column field="begin_br"  header="Início"></Column>
				<Column field="end_br"  header="Fim"></Column>
				<Column field="price_br" alignHeader='right' className='text-right' header="Valor"></Column>
				<Column body={detailButton} header=""></Column>
			</DataTable>
		</section>
	);
}