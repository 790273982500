import banner from 'assets/banner.png';
import axios from 'api';
import { useEffect, useState } from 'react';
import styles from './Banner.module.scss';
import './Regulamento.css';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

export default function Banner() {

	let timeout = setTimeout(() => '');
	const [time, setTime] = useState(0);
	const [seg, setSeg] = useState('00');
	const [min, setMin] = useState('00');
	const [hou, setHou] = useState('00');
	const [day, setDay] = useState('00');

	useEffect(() => {
		axios.get('event/remaining')
			.then(res => {
				setTime(res.data.data);
				runTimer(res.data.data);
			});
	}, []);
	
	useEffect(() => {
		return () => {
			clearTimeout(timeout);
		};
	}, []);

	const runTimer = (seconds : number) => {

		if (seconds < 0) return;

		const s = Math.max(0, Math.trunc(seconds % 60));
		const m = Math.max(0, Math.trunc((seconds - s) / 60 % 60));
		const h = Math.max(0, Math.trunc((seconds - (m * 60) - s) / 3600 % 24));
		const d = Math.max(0, Math.trunc((seconds - (h * 3600) - (m * 60) - s) / (3600 * 24)));

		setSeg(s.toString().padStart(2, '0'));
		setMin(m.toString().padStart(2, '0'));
		setHou(h.toString().padStart(2, '0'));
		setDay(d.toString().padStart(2, '0'));


		timeout = setTimeout(() => {
			runTimer(seconds - 1);
		}, 1000);
	};
	

	return (
		<div className={`my-3 w-12 ${styles.container}`}>
			<img src={banner} alt="Imagem Home" className='w-full' />
			<div className={styles.countdown_container}>
				<div>
					<Card>
						<div className={styles.countdown_item}>
							<span className={styles.countdown_real}>{day}</span>
							<span className={styles.countdown_unidade}>DIAS</span>
						</div>
						<span>:</span>
						<div className={styles.countdown_item}>
							<span>{hou}</span>
							<span className={styles.countdown_unidade}>HORAS</span>
						</div>
						<span>:</span>
						<div className={styles.countdown_item}>
							<span>{min}</span>
							<span className={styles.countdown_unidade}>MINUTOS</span>
						</div>
						<span>:</span>
						<div className={styles.countdown_item}>
							<span>{seg}</span>
							<span className={styles.countdown_unidade}>SEGUNDOS</span>
						</div>
					</Card>
				</div>
			</div>
			{/* <div style={{position: 'fixed', bottom: 10, right: 10 }}>
				<a href='/api/events/REGULAMENTO_OFICIAL_VI_INTERMED_NORDESTE.pdf' id='regulamento' rel="noreferrer" target="_blank" className='p-button p-component p-button-info'><i className="pi pi-download mr-2"></i>BAIXAR REGULAMENTO</a>
			</div> */}
		</div>
	);
}