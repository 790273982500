import {Link, Outlet} from 'react-router-dom';
import styles from './Menu.module.scss';

export default function ProtectedMenu() {
	const permission = localStorage.getItem('ITM_PER');
	const menus = ['profile'];
	if (permission == 'club_manager') menus.push('reports');
	if (permission == 'admin') {
		menus.push('reports');
		menus.push('batch');
		menus.push('gallery');
	}

	return (
		<div>
			<nav className={styles.menu_container}>
				<ul className={`${styles.menu} mx-2 lg:mx-5`}>
					{menus.includes('profile') && <li>
						<Link to='/profile'>Perfil</Link>
					</li>}
					{menus.includes('reports') && <li>
						<Link to='/reports'>Relatorios</Link>
					</li>}
					{menus.includes('batch') && <li>
						<Link to='/batch'>Lotes</Link>
					</li>}
					{menus.includes('gallery') && <li>
						<Link to='/gallery'>Galeria</Link>
					</li>}
				</ul>
			</nav>
		</div>
	);
}