import axios from 'api';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';

export default function NewGallery() {

	const toast = useRef<Toast>(null);
	const [upload, setUpload] = useState(true);
	const navigate = useNavigate();

	let data = new FormData();

	useEffect(() => {
		data = new FormData();
	}, []);

	const create = (ev: React.FormEvent<HTMLFormElement>) => {
		ev.preventDefault();
		
		axios.post('photo/upload', data, {
			headers: { 'content-type': 'multipart/form-data' }
		})
			.then(res => {
				toast.current?.show({severity: 'success', summary: 'Sucesso', detail: 'Cadastro realizado, redirecionando...'});
				navigate('/gallery');
			})
			.catch(err => {
				toast.current?.show({severity: 'error', summary: 'Falha', detail: err.response.data.messages.replace('Already in use', 'Já cadastrado')});
			});
	};

	const addImagem = (item : Blob, i : number) => {
		const name = 'photo';
		data.append(name, item);
	};

	const footer = <span>
		<Button label='Cadastrar' disabled={!upload} type='submit' icon='pi pi-check' style={{marginRight: '.25em'}}/>
	</span>;

	return (
		<section>
			<Toast ref={toast} style={{opacity: 1}}></Toast>

			<form onSubmit={create} className="w-12 my-3">
				<Card title='Adicionar Fotos' footer={footer} className='w-12 inline-block'>
					{new Array(10).fill(0).map((e, i) => {
						const name = 'photo' + (i > 0 ? ('_' + i.toString()) : '');
						return (
							<span key={i} className='p-float-label mt-5 m-2 w-12 inline-block'>
								<InputText  id={name} type='file' onChange={ev => ev.target.files?.length && addImagem(ev.target.files[0], i)} />
								<label style={{ top: '-.75rem', fontSize: '12px' }} htmlFor={name}>Imagem</label>
							</span>
						);
					})}
				</Card>
			</form>
		</section>
	);
}