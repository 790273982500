import Banner from './Banner';
import About from './About';
import Clubs from './Clubs';
import Gallery from './Gallery';

export default function Home () {
	return (
		<section>
			<Banner/>
			<About />
			<Clubs />
			<Gallery />
		</section>
	);
}