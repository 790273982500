import axios from 'api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';

export default function Gallery() {

	const [gallery, setGallery] = useState([]);
	const navigate = useNavigate();
	const toast = useRef<Toast>(null);

	useEffect(() => {
		searchGallery();
	}, []);

	const searchGallery = () => {
		axios.get('photo')
			.then(res => {
				setGallery(res.data.data);
			});
	};

	const remove = (row: any) => {
		axios.delete(`photo/${row.id}`)
			.then(res => {
				toast.current?.show({severity: 'success', summary: 'Sucesso', detail: 'Foto Removida'});
				searchGallery();
			})
			.catch(err => {
				toast.current?.show({severity: 'error', summary: 'Falha', detail: err.response.data.messages});
			});
	};

	const redirectNew = () => {
		navigate('new');
	};

	const detailButton = (rowData: any) => {
		return (
			<Button label='Excluir' icon='pi pi-trash' className="p-button-danger p-1" type='button' onClick={() => remove(rowData)} />
		);
	};

	const imageBodyTemplate = (rowData: any) => {
		return (
			<div className='flex align-items-center justify-content-center'>
				<img src={`/api${rowData.path}`} alt='Imagem' className="mr-2" style={{ height: '150px', width: '150px'}} />
			</div>
		);
	};

	return (
		<section className='my-3'>
			<Toast ref={toast} style={{opacity: 1}}></Toast>

			<Button label='Cadastrar' icon='pi pi-plus' style={{marginRight: '.25em'}} onClick={redirectNew} />


			<DataTable value={gallery} stripedRows paginator responsiveLayout='scroll' 
				paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
				currentPageReportTemplate="Mostrando {first} ~ {last} de {totalRecords}" rows={10}
				emptyMessage="Nenhum Resultado Encontrado" className='my-3'>
				<Column body={imageBodyTemplate} header=""></Column>
				<Column body={detailButton} header=""></Column>
			</DataTable>
		</section>
	);
}