// import eventBanner from 'assets/banner.jpg';
import { Card } from 'primereact/card';
import styles from './About.module.scss';

export default function About () {
	return (
		<Card title="Sobre o evento" className='text-center my-3'>
			<p className={styles.about_text}>
				Há algo que habita embaixo dos casarões de São Luís: a lenda diz que uma grande serpente encantada vive adormecida entre as galerias do centro histórico, crescendo mais a cada dia. No momento em que a cabeça encontrar a cauda, a ilha afundará até as profundezas do oceano. 
				<br/>
				<br/>
				Esperamos vocês para explorar a Ilha de Encantos e desvendar esse mistério no VIII INTERMED NE. 
				<br/>
				<br/>
				Sejam bem-vindos a São Luís, onde as lendas ganham vida!
			</p>
		</Card>
	);
}