import {Link, Outlet, useNavigate} from 'react-router-dom';
import styles from './Menu.module.scss';

export default function Menu() {
	let user = localStorage.getItem('ITM_USR');
	const navigate = useNavigate();

	const clearSession = () => {
		localStorage.clear();
		user = null;
		navigate('/');
	};

	return (
		<div>
			<nav className={styles.menu_container}>
				<ul className={`${styles.menu} mx-2 lg:mx-5`}>
					<li>
						<Link to='/'>Início</Link>
					</li>
					{/* <li>
						<Link to='/party'>Festa Aberta</Link>
					</li>
					<li>
						<a href='http://www.playentretenimento.com' target="_blank" rel="noreferrer" >CASHLESS</a>
					</li> */}

					{user && <li><Link to='/profile'>Perfil</Link></li>}
					<li>
						{!user && <Link to='/login'>Entrar</Link>}
						{user && <Link to='/' onClick={clearSession}>Sair</Link>}
					</li>
				</ul>
			</nav>
			<main className='mx-2 lg:mx-5 flex justify-content-center'>
				<div className='flex-1' style={{maxWidth: '1350px', width: '100%'}}>
					<Outlet />
				</div>
			</main>
		</div>
	);
}