import axios from 'axios';
import { useEffect, useState } from 'react';
import styles from './Clubs.module.scss';

import { IClub } from 'types';
import Club from './Club';
import { Card } from 'primereact/card';

export default function Clubs() {
	const [clubs, setClubs] = useState<IClub[]>();

	useEffect(() => {
		axios.get('/api/club')
			.then(res => {
				setClubs(res.data.data);
			}).catch(err => {
				console.log(err);
			});
	}, []);
	return (
		<section>
			<Card title="Atléticas" className={`${styles.clubs_container} text-center`}>
				<div className='w-full my-5 flex-column align-items-center'>
					<span className={styles.organizer}>ORGANIZAÇÃO</span>
					<Club {...clubs?.find(c => c.id == 5)} highlight />
					<Club {...clubs?.find(c => c.id == 7)} highlight />
				</div>
				{clubs && clubs.filter(c => ![5,7].includes(c.id!)).sort((a,b) => {
					let [pontos_a, pontos_b] = [a.url_logo ? 0 : 10, b.url_logo ? 0 : 10];
					pontos_a += a.name! < b.name! ? -1 : 1;
					pontos_b += 0;
					return pontos_a - pontos_b;
				}).map(club => (
					<Club key={club.id} {...club} />
				))}
			</Card>
		</section>
	);
}