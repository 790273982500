import Menu from 'components/Menu';
import ProtectedRoute from 'components/ProtectedRoute';
import Batch from 'pages/Batch';
import NewBatch from 'pages/Batch/new';
import Gallery from 'pages/Gallery';
import NewGallery from 'pages/Gallery/new';
import Home from 'pages/Home';
import LoginForm from 'pages/Login';
import Party from 'pages/Party';
import NotFound from 'pages/NotFound';
import Profile from 'pages/Profile';
import Report from 'pages/Reports';
import ResetPassword from 'pages/ResetPassword';
import UserDetail from 'pages/UserDetail';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

export default function AppRoutes() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Menu />}>
					<Route index element={<Home />} />
					<Route path="login" element={<LoginForm />} />
					{/* <Route path="party" element={<Party />} /> */}
					<Route path="resetPassword" element={<ResetPassword />} />
					<Route element={<ProtectedRoute />} >
						<Route path="profile" element={<Profile />} />
						<Route path="reports" element={<Report />} />
						<Route path="userDetail/:detail_cpf" element={<UserDetail />} />
						<Route path="batch" element={<Batch />} />
						<Route path="batch/new" element={<NewBatch />} />
						<Route path="gallery" element={<Gallery />} />
						<Route path="gallery/new" element={<NewGallery />} />
					</Route>
				</Route>
				<Route path="*" element={<NotFound />} />
				<Route path="/ticket/:id" element={<Report />} />
			</Routes>
		</BrowserRouter>
	);
}
