import axios from 'axios';

let headers = undefined;
if (localStorage.getItem('ITM_TKN')) {
	headers = {
		Authorization: `Bearer ${localStorage.getItem('ITM_TKN')}`
	};
}


export default axios.create({
	baseURL: '/api/',
	headers
});