import axios from 'api';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';

export default function NewBatch() {
	const [description, setDescription] = useState('');
	const [begin_date, setBegin_date] = useState('');
	const [end_date, setEnd_date] = useState('');
	const [price, setPrice] = useState('');

	const toast = useRef<Toast>(null);
	const navigate = useNavigate();

	const brPrice = (value = '') => {
		if (!value) return '0';
		let limpo = parseInt(value.replace(/\.|,/g, ''));
		limpo /= 100;
		const [v1, v2] = limpo.toString().split('.');
		let nv = '';
		for (let i = v1.length - 1; i >= 0; i--) {
			nv = (nv.length > 2 && nv.replace(/\.|,/g, '').length % 3 == 0 ? '.' : '') + nv;
			nv = v1[i] + nv;
		}
		return nv + ',' + (v2 || '00').padEnd(2, '0');
	};
	

	const create = (ev: React.FormEvent<HTMLFormElement>) => {
		ev.preventDefault();
		axios.post('batch', {
			description,
			begin_date: begin_date.split('/').reverse().join('-'),
			end_date: end_date.split('/').reverse().join('-'),
			price: price.replace(/\./g, '').replace(',', '.')
		})
			.then(res => {
				toast.current?.show({severity: 'success', summary: 'Sucesso', detail: 'Lote Cadastrado'});
				setTimeout(() => navigate('/batch'), 1000);
			})
			.catch(err => {
				toast.current?.show({severity: 'error', summary: 'Falha', detail: err.response.data.messages});
			});
	};

	return (
		<section>
			<Toast ref={toast} style={{opacity: 1}}></Toast>

			<form onSubmit={create} className="w-12 my-3">
				<Card title='Cadastrar Lote' className='w-12 inline-block'>
					<div className='flex-1 align-self-center'>
						<span className='p-float-label mt-5 m-2'>
							<InputText required id='description' className="w-full" value={description} onChange={ev => setDescription(ev.target.value)} />
							<label htmlFor='description'>Descrição</label>
						</span>
						
						<span className='p-float-label mt-5 m-2 sm:w-12 md:w-3 inline-block'>
							<InputMask mask='99/99/9999' required id='begin_date' className="w-full" value={begin_date} onChange={ev => setBegin_date(ev.target.value)} />
							<label htmlFor='begin_date'>Data de Início</label>
						</span>

						<span className='p-float-label mt-5 m-2 sm:w-12 md:w-3 inline-block'>
							<InputMask mask='99/99/9999' required id='end_date' className="w-full" value={end_date} onChange={ev => setEnd_date(ev.target.value)} />
							<label htmlFor='end_date'>Data Fim</label>
						</span>

						<span className='p-float-label mt-5 m-2 sm:w-12 md:w-3 inline-block'>
							<InputText required id='price' className="w-full" value={price} onChange={ev => setPrice(brPrice(ev.target.value))} />
							<label htmlFor='price'>Valor</label>
						</span>

						<span className='p-float-label mt-5 m-2 sm:w-12 md:w-3 inline-block'>
							<Button label='Cadastrar' type='submit' icon='pi pi-save' style={{marginRight: '.25em'}}/>
						</span>
						
					</div>
				</Card>
			</form>
		</section>
	);
}