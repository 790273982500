import { IClub } from 'types';
import style from './Club.module.scss';

export default function Club({url_logo, name, description, highlight} : IClub) {
	return (
		<div className={`${highlight ? style.highlight : style.club_container}`}>
			<p>
				{name}
			</p>
			<img src={`/api${url_logo}`} alt={`${name} Logo`} className={style.club_image} />
			<p>
				{description}
			</p>
		</div>
	);
}