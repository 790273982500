import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import axios from '../../api';
import { IBatch, IClub, IType } from 'types';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link, Navigate } from 'react-router-dom';

export default function Report() {

	const true_false_list = [{id: 'all', name: 'Todos'}, {id: 'paid', name: 'Sim'}, {id: 'not_paid', name: 'Não'}];
	const check_admin_list = [{id: 'all', name: 'Todos'}, {id: 'true', name: 'Sim'}, {id: 'false', name: 'Não'}];
	const need_accommodation_list = [{id: 'undefined', name: 'Todos'}, {id: true, name: 'Sim'}, {id: false, name: 'Não'}];
	const permission = localStorage.getItem('ITM_PER');
	
	const [userRedirect, setUserRedirect] = useState<any>();
	const [paid, setPaid] = useState('all');
	const [check_admin, setCheck_admin] = useState('all');
	const [name, setName] = useState('');
	const [tickets, setTickets] = useState([]);
	const [resume, setResume] = useState<any>({amount: 0, sum_br: '0,00'});
	const [cpf, set_cpf] = useState('');
	const [club, set_club] = useState('');
	const [type, set_type] = useState('');
	const [born_year, set_born_year] = useState('');
	const [batch, set_batch] = useState('');
	const [need_accommodation, set_need_accommodation] = useState('undefined');
	const [listClubs, setListClubs] = useState<IClub[]>([]);
	const [listTypes, setListTypes] = useState<IType[]>([]);
	const [listBatches, setListBatches] = useState<IBatch[]>([]);

	useEffect(() => {
		axios.get('club')
			.then(res => {
				setListClubs([{id: 0, name: 'Selecione uma opção'}].concat(res.data.data));
			});
		axios.get('batch')
			.then(res => {
				setListBatches([{id: 0, description: 'Selecione uma opção'}].concat(res.data.data));
			});
		axios.get('user/type')
			.then(res => {
				setListTypes([{id: 0, name: 'Selecione uma opção'}].concat(res.data.data));
			});

		searchTickets();
	}, []);

	function search(ev: React.FormEvent<HTMLFormElement>) {
		ev.preventDefault();
		searchTickets();
	}

	const searchTickets = () => {
		axios.get('event/ticket', {
			params: {
				name,
				cpf,
				club,
				paid,
				batch,
				type,
				need_accommodation,
				born_year,
				check_admin
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem('ITM_TKN')}`
			}
		})
			.then(res => {				
				setTickets(res.data.data.tickets);
				setResume(res.data.data.resume);
			});
	};

	const imageBodyTemplate = (rowData: any) => {
		return (
			<div className='flex align-items-center'>
				<img src={`/api${rowData.user_bought?.Club?.url_logo}`} alt='Logo' className="mr-2" style={{ height: '2rem', width: '2rem'}} />
				<span>{rowData?.user_bought?.Club?.name} </span>
			</div>
		);
	};

	const ticketPaid = (rowData: any) => {
		return (
			<span>{rowData?.paid ? 'Sim' : 'Não'} </span>
		);
	};

	const needAccommodation = (rowData: any) => {
		return (
			<span>{rowData?.user_bought?.need_accommodation ? 'Sim' : 'Não'} </span>
		);
	};

	const checkAdmin = (rowData: any) => {
		return (
			<span>{rowData?.check_admin ? 'Sim' : 'Não'} </span>
		);
	};

	const detailButton = (rowData: any) => {		
		const cpf = rowData.user_bought?.cpf;
		return (
			<Link to={`/userDetail/${cpf}`} target="_blank" className='p-button' style={{marginRight: '.25em'}}> Detalhes</Link>
		);
	};

	const footer = <span>
		<Button label='Buscar' type='submit' icon='pi pi-search' style={{marginRight: '.25em'}}/>
	</span>;

	if (userRedirect) {
		return <Navigate to='/userDetail' state={userRedirect} />;
	}

	return (
		<section>
			<form onSubmit={search}>
				<Card title="Filtros" className='my-3 flex'  footer={footer}>
					<span className='p-float-label mt-5 m-2 w-12 md:w-5 inline-block'>
						<InputText id='name' className="w-full" value={name} onChange={ev => setName(ev.target.value)} />
						<label htmlFor='name'>Nome</label>
					</span>

					<span className='p-float-label mt-5 m-2 w-12 md:w-2 inline-block'>
						<InputMask mask='999.999.999-99' id='cpf' className="w-full" value={cpf} onChange={ev => set_cpf(ev.target.value)} />
						<label htmlFor='cpf'>CPF</label>
					</span>

					{permission == 'admin' && <span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
						<Dropdown className="w-full" value={club} optionLabel='name' optionValue='id' id='club' options={listClubs} onChange={e => set_club(e.value)} placeholder='Atlética'/>
						<label htmlFor='club' style={{ top: '-.75rem', fontSize: '12px' }}>Atlética</label>
					</span>}

					<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
						<Dropdown className="w-full" value={paid} optionLabel='name' optionValue='id' id='paid' options={true_false_list} onChange={e => setPaid(e.value)}/>
						<label htmlFor='paid' style={{ top: '-.75rem', fontSize: '12px' }}>Aprovado</label>
					</span>

					<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
						<Dropdown className="w-full" value={batch} optionLabel='description' optionValue='id' id='batch' options={listBatches} onChange={e => set_batch(e.value)} placeholder='Lote'/>
						<label htmlFor='batch' style={{ top: '-.75rem', fontSize: '12px' }}>Lote</label>
					</span>

					<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
						<Dropdown className="w-full" value={type} optionLabel='name' optionValue='id' id='type' options={listTypes} onChange={e => set_type(e.value)} placeholder='Tipo'/>
						<label htmlFor='type' style={{ top: '-.75rem', fontSize: '12px' }}>Tipo *</label>
					</span>

					<span className='p-float-label mt-5 m-2 w-12 md:w-2 inline-block'>
						<Dropdown className="w-full" value={need_accommodation} optionLabel='name' optionValue='id' id='need_accommodation' options={need_accommodation_list} onChange={e => set_need_accommodation(e.value)}/>
						<label htmlFor='need_accommodation' style={{ top: '-.75rem', fontSize: '12px' }}>Necessita Alojamento?</label>
					</span>

					<span className='p-float-label mt-5 m-2 w-12 md:w-2 inline-block'>
						<InputMask mask='9999' id='born_year' className="w-full" value={born_year} onChange={ev => set_born_year(ev.target.value)} />
						<label htmlFor='born_year'>Ano de Nascimento</label>
					</span>

					{permission == 'admin' && <span className='p-float-label mt-5 m-2 w-12 md:w-2 inline-block'>
						<Dropdown className="w-full" value={check_admin} optionLabel='name' optionValue='id' id='check_admin' options={check_admin_list} onChange={e => setCheck_admin(e.value)}/>
						<label htmlFor='check_admin' style={{ top: '-.75rem', fontSize: '12px' }}>Verificado ADM?</label>
					</span>}

				</Card>
			</form>


			<Card className='my-3 flex'>
				<span className='w-full mb-3'>Quantidade: {resume.amount}</span>
				<br/>
				<br/>
				<span className='w-full'>Total: {resume.sum_br}</span>
			</Card>

			<DataTable value={tickets} stripedRows paginator responsiveLayout='scroll' 
				paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
				currentPageReportTemplate="Mostrando {first} ~ {last} de {totalRecords}" rows={10}
				emptyMessage="Nenhum Resultado Encontrado" className='my-3'>
				<Column field="user_bought.name" header="Nome"></Column>
				<Column field="user_bought.cpf_formated"  header="CPF"></Column>
				<Column body={imageBodyTemplate} header="Atlética"></Column>
				<Column body={ticketPaid} header="Aprovado"></Column>
				<Column body={needAccommodation} header="Necessita Alojamento?"></Column>
				<Column body={checkAdmin} header="Verificado ADM?"></Column>
				<Column body={detailButton} header=""></Column>
			</DataTable>
		</section>
	);
}