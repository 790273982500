import axios from 'axios';
import { useEffect, useState } from 'react';
import { IPhoto } from 'types';
import { Galleria } from 'primereact/galleria';
import styles from './Gallery.module.scss';

export default function Gallery() {

	const [photos, setPhotos] = useState<any>();

	useEffect(() => {
		axios.get('/api/photo')
			.then(res => {
				const mapped = res.data.data.map((f: IPhoto, idx : number) => ({
					src: `/api${f.path}?${idx}`
				}));
				setPhotos([...mapped]);
			}).catch(err => {
				console.log(err);
			});
	}, []);

	const responsiveOptions = [
		{
			breakpoint: '1024px',
			numVisible: 5
		},
		{
			breakpoint: '768px',
			numVisible: 3
		},
		{
			breakpoint: '560px',
			numVisible: 1
		}
	];

	const itemTemplate = (item : any) => {
		return <img src={item.src} onError={(e) => e.currentTarget.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.alt} style={{ width: '100%' }} />;
	};

	const thumbnailTemplate = (item : any) => {
		return <img src={item.src} onError={(e) => e.currentTarget.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={item.alt} style={{maxWidth: '100px', maxHeight: '100px'}} />;
	};


	return (

		<div className="card my-3">
			<Galleria value={photos} responsiveOptions={responsiveOptions} numVisible={8}
				showItemNavigators
				item={itemTemplate} thumbnail={thumbnailTemplate} 
				className={styles.gall}
			/>
		</div>
	);
}