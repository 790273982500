import axios from 'api';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { IBatch, IClub, IState, IType, IUser } from 'types';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

export default function UserDetail() {

	interface IData {
		id: number,
		user_bought: IUser,
		batch_bought: IBatch,
		batch_accepted?: IBatch,
		paid: boolean
	}
	const navigate = useNavigate();
	const permission = localStorage.getItem('ITM_PER');
	const userAdmin = permission == 'admin';
	const userManager = permission == 'club_manager';

	const [stateSelectItems, setSelect] = useState<IState[]>([]);
	const [citiesSelectItems, setCities] = useState<IState[]>([]);

	const [user, setUser] = useState<IUser>();
	const [data, setData] = useState<IData>();

	const toast = useRef<Toast>(null);
	const [listClubs, setListClubs] = useState<IClub[]>([]);
	const [listTypes, setListTypes] = useState<IType[]>([]);
	const [listBatches, setListBatches] = useState<IBatch[]>([]);
	const [checked, setChecked] = useState<boolean>(false);
	const [approved, setApproved] = useState<boolean>(false);
	const [check_admin, setCheck_admin] = useState<boolean>(false);
	const [clubManager, set_clubManager] = useState(0);

	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [nickname, setNickname] = useState('');
	const [birth_date, set_birth_date] = useState('');
	const [rg, set_rg] = useState('');
	const [cpf, set_cpf] = useState('');
	const [street, set_street] = useState('');
	const [number, set_number] = useState('');
	const [city, set_city] = useState('');
	const [state, set_state] = useState('');
	const [type, set_type] = useState('');
	const [phone, set_phone] = useState('');
	const [club, set_club] = useState('');
	const [photo, set_photo] = useState<Blob | string>('');
	const [vaccine_card, set_vaccine_card] = useState<Blob | string>('');
	const [enroll, set_enroll] = useState<Blob | string>('');
	const [college, set_college] = useState('');
	const [course, set_course] = useState('');

	const [batch_accepted, setBatchAccepted] = useState('');
	const [batch_price, setBatchPrice] = useState('');
	const { detail_cpf } = useParams();
	

	useEffect(() => {

		axios.get('address/state')
			.then(res => {
				setSelect(res.data.data);
			});
		axios.get('user/type')
			.then(res => {
				setListTypes(res.data.data);
			});
		axios.get('batch')
			.then(res => {
				setListBatches(res.data.data);
			});
		axios.get(`event/ticket/${detail_cpf}`)
			.then(res => {
				const data = res.data.data;
				const user = data.user_bought;
				setUser(user);
				setData(data);

				user.email && setEmail(user.email);
				user.name && setName(user.name);
				user.nickname && setNickname(user.nickname);
				user.date_br && set_birth_date(user.date_br);
				user.rg && set_rg(user.rg);
				user.cpf && set_cpf(user.cpf);
				user.street && set_street(user.street);
				user.number && set_number(user.number);
				user.State && set_state(user.State.id as SetStateAction<string>);
				user.Type && set_type(user.Type.id as SetStateAction<string>);
				user.phone && set_phone(user.phone);
				user.Club && set_club(user.Club.id as SetStateAction<string>);
				user.college && set_college(user.college);
				user.course && set_course(user.course);

				user.id_club_administrator && set_clubManager(user.id_club_administrator);
				setChecked(user.role == 'admin');
				setCheck_admin(data.check_admin);

				if (data.id_batch_accepted) {
					setBatchAccepted(data.id_batch_accepted);
					updateValue(data.batch_accepted.price_br);
				}

			});
	}, []);

	useEffect(() => {
		if (!user) return;
		axios.get('club')
			.then(res => {
				setListClubs([{id: 0, name: 'Nenhuma'}].concat(res.data.data));
				if (user?.State.id) getCities(user.State.id as number);
			});
	}, [user]);

	const updateValue = (force = undefined) => {
		
		if (!force && (!batch_accepted || !listBatches.length)) return;
		if (force) {
			setBatchPrice(force);
		}
		else if (batch_accepted && listBatches.length) { 
			const batch = listBatches.find(x => x.id.toString() == batch_accepted);
			
			batch && setBatchPrice(batch.price_br as SetStateAction<string>);
		}
	};

	useEffect(() => {
		batch_accepted && updateValue();
	}, [batch_accepted]);

	if (!user || !user.id || !data)
		return <div className='mt-3'>Carregando Dados...</div>;


	const stateChange = (ev : DropdownChangeParams) => {
		set_state(ev.value);
		getCities(ev.value);
	};

	const getCities = (id_state : number) => {

		axios.get('address/city', {
			params: {
				id_state
			}
		})
			.then(res => {
				setCities(res.data.data);
				if (user.City.id) set_city(user.City.id as SetStateAction<string>);
			})
			.catch(err => console.warn(err));
	};

	const confirmarRejeicao = () => {
		confirmDialog({
			message: 'Tem certeza que deseja rejeitar a inscrição? Isso apagará as informações do usuário cadastrado.',
			header: 'Confirmação',
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: 'Confirmar Rejeição',
			rejectLabel: 'Cancelar',
			accept: rejeitarInscricao
		});
	};
	
	const pode_editar = permission == 'club_manager' ? !data.paid : true;
	const pode_editar_anexo = permission == 'club_manager' && [5,6].includes(parseInt(user.id_type ? user.id_type.toString() : '0'));

	const atualizarPermissoes = () => {		
		axios.patch('club/setAdministrator', {
			id_user: user.id,
			id_club: clubManager,
			admin: checked
		}).then(res => {
			toast.current?.show({severity: 'success', summary: 'Sucesso', detail: 'Informações Alteradas'});
		}).catch(err => {
			toast.current?.show({severity: 'error', summary: 'Falha', detail: err.response.data.messages});
		});
	};

	const atualizarLote = () => {		
		axios.patch('event/updateTicketBatch', {
			id_tickets: [data.id],
			new_batch: batch_accepted
		}).then(res => {
			toast.current?.show({severity: 'success', summary: 'Sucesso', detail: 'Informações Alteradas'});
		}).catch(err => {
			toast.current?.show({severity: 'error', summary: 'Falha', detail: err.response.data.messages});
		});
	};

	const aprovarInscricao = () => {		
		axios.patch('event/acceptTickets', {
			id_tickets: [data.id]
		}).then(res => {
			setApproved(true);
			toast.current?.show({severity: 'success', summary: 'Sucesso', detail: 'Aprovação Concluída'});
		}).catch(err => {
			toast.current?.show({severity: 'error', summary: 'Falha', detail: err.response.data.messages});
		});
	};

	const rejeitarInscricao = () => {		
		axios.patch('event/rejectTickets', {
			id_tickets: [data.id]
		}).then(res => {
			setApproved(true);
			toast.current?.show({severity: 'success', summary: 'Sucesso', detail: 'Rejeição Concluída'});
			setTimeout(() => navigate('/reports'), 2000);
		}).catch(err => {
			toast.current?.show({severity: 'error', summary: 'Falha', detail: err.response.data.messages});
		});
	};

	const updateUser = () => {
		
		const data = new FormData();

		data.append('id', user.id.toString());
		data.append('email', email);
		data.append('name', name);
		data.append('nickname', nickname);
		data.append('birth_date', birth_date.split('/').reverse().join('-'));
		data.append('rg', rg);
		data.append('cpf', cpf.replace(/\.|-/g, ''));
		data.append('street', street);
		data.append('number', number);
		data.append('id_city', city);
		data.append('id_state', state);
		data.append('id_type', type);
		data.append('phone', phone);
		data.append('id_club', club);
		data.append('college', college);
		data.append('course', course);
		data.append('check_admin', check_admin.toString());
		if (photo) {
			data.append('photo', photo);
		}
		if (vaccine_card) {
			data.append('vaccine_card', vaccine_card);
		}
		if (enroll) {
			data.append('enroll', enroll);
		}
		
		axios.put('user', data, {
			headers: { 'content-type': 'multipart/form-data' }
		})
			.then(res => {
				toast.current?.show({severity: 'success', summary: 'Sucesso', detail: 'Atualização realizada...'});
			})
			.catch(err => {
				let messages = '';
				if (Array.isArray(err.response.data.messages)) {
					messages = err.response.data.messages.join('<br/>');
				} else {
					messages = err.response.data.messages.replace('Already in use', 'Já cadastrado');
				}

				
				toast.current?.show({severity: 'error', summary: 'Falha', detail: messages});
			});

	};


	const nomeAnexo = () => {
		if (type == '3') return 'Declaração de convidado';
		if (type == '5') return 'Declaração de conclusão de curso';
		if (type == '6') return 'Declaração de comissão técnica';
		return 'Comprovante de Matrícula';
	};
	
	const footer_permissoes = <span>
		<Button label='Atualizar' type='button' icon='pi pi-refresh' onClick={atualizarPermissoes} style={{marginRight: '.25em'}}/>
	</span>;

	const footer_compra = <span>
		{(userAdmin || userManager) && <Button label='Aprovar' disabled={approved || data.paid} type='button' className='p-button-success' icon='pi pi-check' onClick={aprovarInscricao} style={{marginRight: '.25em'}}/> }
		{(userAdmin || userManager) && <Button label='Rejeitar' type='button' className='p-button-danger' icon='pi pi-times' onClick={confirmarRejeicao} style={{marginRight: '.25em'}}/> }
	</span>;

	const footer_update = <span>
		{(userAdmin || userManager) && <Button label='Atualizar Informações' disabled={!pode_editar && !pode_editar_anexo} type='button' className='p-button-success' icon='pi pi-check' onClick={updateUser} style={{marginRight: '.25em'}}/> }
	</span>;

	return (
		<>
			<ConfirmDialog />
			<Toast ref={toast} style={{opacity: 1}}></Toast>
			<Card title='Informações do Usuário' className='my-3' footer={(pode_editar || pode_editar_anexo) && footer_update}>

				<span className='p-float-label mt-5 m-2 w-12 inline-block'>
					<img src={`/api${user.photo_url}`} alt="Foto do Usuário" style={{height: '200px', width: '200px'}} />
				</span>

				<span className='p-float-label mt-5 m-2 w-12 md:w-4 inline-block'>
					<InputText required id='name' className="w-full" value={name} onChange={ev => setName(ev.target.value)} readOnly={!pode_editar} />
					<label htmlFor='name'>Nome Completo</label>
				</span>
				
				<span className='p-float-label mt-5 m-2 w-12 md:w-4 inline-block'>
					<InputText required id='email' className="w-full" value={email} onChange={ev => setEmail(ev.target.value)}  readOnly={!pode_editar}/>
					<label htmlFor='email'>Email</label>
				</span>
				
				<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
					<InputText id='nickname' maxLength={50} className="w-full" value={nickname} onChange={ev => setNickname(ev.target.value)}  readOnly={!pode_editar}/>
					<label htmlFor='nickname'>Apelido</label>
				</span>

				<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
					<InputMask mask='99/99/9999' required id='birth_date' className="w-full" value={birth_date} onChange={ev => set_birth_date(ev.target.value)}  readOnly={!pode_editar}/>
					<label htmlFor='birth_date'>Data de Nascimento</label>
				</span>

				<span className='p-float-label mt-5 m-2 w-12 md:w-2 inline-block'>
					<InputMask mask='99999?99999999' required id='rg' className="w-full" value={rg} onChange={ev => set_rg(ev.target.value)}  readOnly={!pode_editar}/>
					<label htmlFor='rg'>RG</label>
				</span>

				<span className='p-float-label mt-5 m-2 w-12 md:w-2 inline-block'>
					<InputMask mask='999.999.999-99' required id='cpf' className="w-full" value={cpf} onChange={ev => set_cpf(ev.target.value)}  readOnly={!pode_editar}/>
					<label htmlFor='cpf'>CPF</label>
				</span>
				
				<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
					<Dropdown className="w-full" value={state} optionLabel='name' optionValue='id' id='state' options={stateSelectItems} onChange={stateChange} placeholder='' disabled={!pode_editar}/>
					<label htmlFor='state'>Estado</label>
				</span>

				<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
					<Dropdown className="w-full" value={city} optionLabel='name' optionValue='id' id='city' options={citiesSelectItems} onChange={e => set_city(e.value)} placeholder='' disabled={!pode_editar}/>
					<label htmlFor='city'>Cidade</label>
				</span>

				<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
					<InputText required id='street' className="w-full" value={street} onChange={ev => set_street(ev.target.value)}  readOnly={!pode_editar}/>
					<label htmlFor='street'>Rua</label>
				</span>

				<span className='p-float-label mt-5 m-2 w-12 md:w-2 inline-block'>
					<InputMask mask='?999999' required id='number' className="w-full" value={number} onChange={ev => set_number(ev.target.value)}  readOnly={!pode_editar}/>
					<label htmlFor='number'>Número</label>
				</span>

				<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
					<InputMask mask='(99) 9 9999-9999' required id='phone' className="w-full" value={phone} onChange={ev => set_phone(ev.target.value)}  readOnly={!pode_editar}/>
					<label htmlFor='phone'>Telefone</label>
				</span>

				<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
					<Dropdown className="w-full" value={type} optionLabel='name' optionValue='id' id='type' options={listTypes} onChange={e => set_type(e.value)} placeholder='Tipo' readOnly={!pode_editar}/>
					<label htmlFor='type' style={{ top: '-.75rem', fontSize: '12px' }}>Tipo</label>
				</span>

				<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
					<Dropdown className="w-full" value={club} optionLabel='name' optionValue='id' id='club' options={listClubs} onChange={e => set_club(e.value)} placeholder='Atlética' disabled={!pode_editar}/>
					<label htmlFor='club' style={{ top: '-.75rem', fontSize: '12px' }}>Atlética</label>
				</span>

				<span className='p-float-label mt-5 m-2 w-12 md:w-5 inline-block'>
					<InputText id='college' maxLength={100} className="w-full" value={college} onChange={ev => set_college(ev.target.value)}  readOnly={!pode_editar}/>
					<label htmlFor='college'>Instituição</label>
				</span>

				<span className='p-float-label mt-5 m-2 w-12 md:w-5 inline-block'>
					<InputText id='course' maxLength={100} className="w-full" value={course} onChange={ev => set_course(ev.target.value)}  readOnly={!pode_editar}/>
					<label htmlFor='course'>Curso</label>
				</span>

				<span className='p-float-label mt-5 m-2 w-12 md:w-5 inline-block'>
					<InputText id='need_accommodation' maxLength={100} className="w-full" value={user.need_accommodation ? 'Sim, vou precisar de alojamento' : 'Não necessito de alojamento'} readOnly/>
					<label htmlFor='need_accommodation'>Vai precisar de Alojamento?</label>
				</span>

				{ pode_editar && 
					<span className='p-float-label mt-5 m-2 w-12 inline-block'>
						<InputText required id='photo' type='file' onChange={ev => ev.target.files?.length && set_photo(ev.target.files[0])  } />
						<label htmlFor='photoLabel' style={{ top: '-.75rem', fontSize: '12px' }}>Foto de Rosto</label>
					</span>
				}

				{ (pode_editar || pode_editar_anexo) && 
					<span className='p-float-label mt-5 m-2 w-12 inline-block'>
						<InputText required id='enroll' type='file' onChange={ev => ev.target.files?.length && set_enroll(ev.target.files[0])} />
						<label style={{ top: '-.75rem', fontSize: '12px' }} htmlFor='enroll'>{nomeAnexo()}</label>
					</span>
				}	

				<span className='p-float-label mt-5 m-2 sm:w-12 md:w-3 block'>
					<a href={`/api${user.enroll_url}`} rel="noreferrer" target="_blank" className='p-button p-component p-button-secondary'>Baixar {nomeAnexo()}</a>
				</span>

				{userAdmin && <span className='p-float-label mt-5 m-2 w-12 inline-block' onClick={e => setCheck_admin(!check_admin)}>
					<Checkbox inputId="check_admin" name='check_admin' onChange={e => setCheck_admin(e.checked)} checked={check_admin}></Checkbox>
					<label htmlFor="check_admin" className="p-checkbox-label ml-3" >Verificado Administração</label>
				</span>}
				

			</Card>

			{userAdmin && <Card title="Permissões" className='my-3' footer={footer_permissoes}>
				<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
					<Dropdown className="w-full" value={clubManager} optionLabel='name' optionValue='id' id='club' options={listClubs} onChange={e => set_clubManager(e.value)} />
					<label htmlFor='club' style={{ top: '-.75rem', fontSize: '12px' }}>Gerente da Atlética</label>
				</span>
				<span className='p-float-label mt-5 m-2 w-12 inline-block' onClick={e => setChecked(!checked)}>
					<Checkbox inputId="cb1" name='cb1' onChange={e => setChecked(e.checked)} checked={checked}></Checkbox>
					<label htmlFor="cb1" className="p-checkbox-label ml-3" >Administrador Geral</label>
				</span>
			</Card>}

			<Card title="Detalhes da Inscrição" className='my-3' footer={footer_compra}>
				<span style={{width: '100%'}}>Lote Comprado</span>
				<span className='p-float-label mt-5 m-2 sm:w-12 md:w-5 inline-block'>
					<InputText id='lote' className="w-full" value={data.batch_bought.description} readOnly/>
					<label htmlFor='lote'>Lote</label>
				</span>
				<span className='p-float-label mt-5 m-2 sm:w-12 md:w-5 inline-block'>
					<InputText id='price' className="w-full" value={data.batch_bought.price_br} readOnly/>
					<label htmlFor='price'>Preço</label>
				</span>
				
				{batch_accepted && <div className='w-full'>
					<span style={{width: '100%', display: 'block'}} className="mt-3">Lote Aceito</span>
					<div className='flex'>
						<span className='p-float-label mt-5 m-2 sm:w-12 md:w-5 inline-block'>
							<Dropdown disabled={!userAdmin} className="w-full" value={batch_accepted} optionLabel='description' optionValue='id' id='batch' options={listBatches} onChange={e => setBatchAccepted(e.value)} /> 
							<label htmlFor='batch' style={{ top: '-.75rem', fontSize: '12px' }}>Lote Aceito</label>
						</span>
						<span className='p-float-label mt-5 m-2 sm:w-12 md:w-5 inline-block'>
							<InputText id='price' className="w-full" value={batch_price} readOnly/>
							<label htmlFor='price'>Preço</label>
						</span>
					</div>
					{userAdmin && <Button label='Atualizar Lote Aceito' type='button' icon='pi pi-refresh' onClick={atualizarLote} style={{marginRight: '.25em'}}/>}
				</div>}

			</Card>
		</>
	);
}