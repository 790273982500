import axios from 'api';
import React, { useRef, useState } from 'react';
import {Password} from 'primereact/password';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IClub, IState, IType } from 'types';
import styles from './Login.module.scss';

export default function LoginForm () {

	const [entrar, setEntrar] = useState(false);
	const toast = useRef<Toast>(null);
	const navigate = useNavigate();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [password2, setPassword2] = useState('');
	const [name, setName] = useState('');
	const [nickname, setNickname] = useState('');
	const [birth_date, set_birth_date] = useState('');
	const [rg, set_rg] = useState('');
	const [cpf, set_cpf] = useState('');
	const [street, set_street] = useState('');
	const [number, set_number] = useState('');
	const [city, set_city] = useState('');
	const [state, set_state] = useState('');
	const [type, set_type] = useState('');
	const [phone, set_phone] = useState('');
	const [club, set_club] = useState('');
	const [photo, set_photo] = useState<Blob | string>('');
	const [vaccine_card, set_vaccine_card] = useState<Blob | string>('');
	const [enroll, set_enroll] = useState<Blob | string>('');
	const [college, set_college] = useState('');
	const [course, set_course] = useState('');
	const [need_accommodation, set_need_accommodation] = useState(false);


	const [listClubs, setListClubs] = useState<IClub[]>([]);
	const [listTypes, setListTypes] = useState<IType[]>([]);
	const [stateSelectItems, setSelect] = useState<IState[]>([]);
	const [citiesSelectItems, setCities] = useState<IState[]>([]);

	useEffect(() => {
		axios.get('address/state')
			.then(res => {
				setSelect(res.data.data);
			});
		axios.get('club')
			.then(res => {
				setListClubs(res.data.data);
			});
		axios.get('user/type')
			.then(res => {
				setListTypes(res.data.data);
			});
	}, []);

	const getCities = (id_state : number) => {

		axios.get('address/city', {
			params: {
				id_state
			}
		})
			.then(res => {
				setCities(res.data.data);
			})
			.catch(err => console.warn(err));
	};
	

	const login = (ev: React.FormEvent<HTMLFormElement>) => {
		ev.preventDefault();
		axios.post('auth/login', {
			email,
			password
		})
			.then(res => {
				toast.current?.show({severity: 'success', summary: 'Sucesso', detail: 'Login Efetuado, carregando informações... Aguarde...', sticky: true});
				localStorage.setItem('ITM_USR', new Array(10).fill('').map(i => parseInt((Math.random() * 100).toString())).join(''));
				localStorage.setItem('ITM_TKN', res.headers.authorization);
				localStorage.setItem('ITM_PER', res.headers['itm-p']);
				window.location.href = '/profile';
			})
			.catch(err => {
				toast.current?.show({severity: 'error', summary: 'Falha', detail: err.response.data.messages});
			});
	};

	const redirectProfile = () => {		
		toast.current?.show({severity: 'success', summary: 'Sucesso', detail: 'Carregando informações... Aguarde...'});
		if (localStorage.getItem('ITM_TKN')) navigate('/profile');
		else setTimeout(redirectProfile, 1500);
	};

	const registrar = (ev: React.FormEvent<HTMLFormElement>) => {

		ev.preventDefault();

		if (!state || !city || !type || !club) {
			toast.current?.show({severity: 'error', summary: 'Falha', detail: 'Preencha todos os campos'});
			return;
		}

		if (password != password2) {
			toast.current?.show({severity: 'error', summary: 'Falha', detail: 'Suas senhas não são iguais'});
			return;
		}

		function TestaCPF(strCPF : string) {
			let Soma;
			let Resto;
			Soma = 0;
			if (strCPF == '00000000000') return false;
			strCPF = strCPF.replace(/\.|-/g, '');

			console.log(strCPF);
		
			for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
			Resto = (Soma * 10) % 11;
		
			if ((Resto == 10) || (Resto == 11))  Resto = 0;
			if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;
		
			Soma = 0;
			for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
			Resto = (Soma * 10) % 11;
		
			if ((Resto == 10) || (Resto == 11))  Resto = 0;
			if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
			return true;
		}


		if (!TestaCPF(cpf)) {
			toast.current?.show({severity: 'error', summary: 'Falha', detail: 'CPF inválido por favor verifique'});
			return;
		}

		const data = new FormData();

		data.append('email', email);
		data.append('password', password);
		data.append('name', name);
		data.append('nickname', nickname);
		data.append('birth_date', birth_date.split('/').reverse().join('-'));
		data.append('rg', rg);
		data.append('cpf', cpf.replace(/\.|-/g, ''));
		data.append('street', street);
		data.append('number', number);
		data.append('id_city', city);
		data.append('id_state', state);
		data.append('id_type', type);
		data.append('phone', phone);
		data.append('id_club', club);
		data.append('college', college);
		data.append('course', course);
		data.append('need_accommodation', need_accommodation.toString());
		if (photo) {
			data.append('photo', photo);
		}
		if (vaccine_card) {
			data.append('vaccine_card', vaccine_card);
		}
		if (enroll) {
			data.append('enroll', enroll);
		}
		
		axios.post('user', data, {
			headers: { 'content-type': 'multipart/form-data' }
		})
			.then(res => {
				toast.current?.show({severity: 'success', summary: 'Sucesso', detail: 'Cadastro realizado, redirecionando...'});
				login(ev);
			})
			.catch(err => {
				let messages = '';
				if (Array.isArray(err.response.data.messages)) {
					messages = err.response.data.messages.join('<br/>');
				} else {
					messages = err.response.data.messages.replace('Already in use', 'Já cadastrado');
				}

				
				toast.current?.show({severity: 'error', summary: 'Falha', detail: messages});
			});
	};

	const stateChange = (ev : DropdownChangeParams) => {
		set_state(ev.value);
		getCities(ev.value);
	};

	const footer = <span>
		<Button label='Cadastrar' type='submit' icon='pi pi-check' style={{marginRight: '.25em'}}/>
		<Button label='Cancelar' type='button' onClick={() => navigate('/') } icon='pi pi-times' className='p-button-secondary mt-2'/>
	</span>;

	const nomeAnexo = () => {
		if (type == '3') return 'Declaração de convidado *';
		if (type == '5') return 'Declaração de conclusão de curso *';
		if (type == '6') return 'Declaração de comissão técnica *';
		return 'Comprovante de Matrícula *';
	};

	return (
		<div className='mx-4'>

			<Toast ref={toast} style={{opacity: 1}}></Toast>

			<div className='text-center my-3'>
				<Card className='w-12 md:w-6 inline-block'>
					<div className='flex-1 align-self-center'>
						<Button onClick={() => setEntrar(false)} className="p-button-link p-0" label="Cadastre-se" /> ou <Button onClick={() => setEntrar(true)} className="p-button-link p-0" label="Faça Login" />
					</div>

					<div className={`mt-3 ${styles.basis}`} >
						<Button onClick={() => navigate('/resetPassword')} className="p-button-link p-0 text-xs" label="Recuperar Minha Senha" />
					</div>
				</Card>
			</div>
			{entrar && 
				<form onSubmit={login} className="w-12 text-center my-3">
					<Card title='Entrar' className='w-12 md:w-6 inline-block'>
						<div className='flex-1 align-self-center'>
							<span className='p-float-label mt-5 m-2'>
								<InputText required id='email' className="w-full" value={email} onChange={ev => setEmail(ev.target.value)} />
								<label htmlFor='email'>Email</label>
							</span>

							<span className='p-float-label mt-5 m-2'>
								<Password required id='password' className="w-full" inputClassName='w-full' value={password} onChange={ev => setPassword(ev.target.value)} feedback={false} />
								<label htmlFor='password'>Senha</label>
							</span>
							<Button label='Entrar' type='submit' icon='pi pi-send' style={{marginRight: '.25em'}}/>
							
						</div>

					</Card>
				</form>
			}
			{!entrar &&
				<form onSubmit={registrar}>
					<Card title='Registre-se' footer={footer} className='my-3'>

						<div style={{width: '100%'}}>
							<small>
								(*) Campos Obrigatórios
							</small>
						</div>
						
						<span className='p-float-label mt-5 m-2 w-12 md:w-4 inline-block'>
							<InputText required id='name' className="w-full" value={name} onChange={ev => setName(ev.target.value)} />
							<label htmlFor='name'>Nome Completo *</label>
						</span>
						
						<span className='p-float-label mt-5 m-2 w-12 md:w-4 inline-block'>
							<InputText required id='email' className="w-full" value={email} onChange={ev => setEmail(ev.target.value)} />
							<label htmlFor='email'>Email *</label>
						</span>
						
						<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
							<InputText id='nickname' maxLength={50} className="w-full" value={nickname} onChange={ev => setNickname(ev.target.value)} />
							<label htmlFor='nickname'>Apelido</label>
						</span>

						<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
							<Password required id='password' className="w-full" inputClassName='w-full' value={password} onChange={ev => setPassword(ev.target.value)} feedback={false} />
							<label htmlFor='password'>Senha *</label>
						</span>
						
						<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
							<Password required id='password2' className="w-full" inputClassName='w-full' value={password2} onChange={ev => setPassword2(ev.target.value)} feedback={false} />
							<label htmlFor='password2'>Repetir Senha *</label>
						</span>

						<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
							<InputMask mask='99/99/9999' required id='birth_date' className="w-full" value={birth_date} onChange={ev => set_birth_date(ev.target.value)} />
							<label htmlFor='birth_date'>Data de Nascimento *</label>
						</span>

						<span className='p-float-label mt-5 m-2 w-12 md:w-2 inline-block'>
							<InputMask mask='99999?99999999' required id='rg' className="w-full" value={rg} onChange={ev => set_rg(ev.target.value)} />
							<label htmlFor='rg'>RG *</label>
						</span>

						<span className='p-float-label mt-5 m-2 w-12 md:w-2 inline-block'>
							<InputMask mask='999.999.999-99' required id='cpf' className="w-full" value={cpf} onChange={ev => set_cpf(ev.target.value)} />
							<label htmlFor='cpf'>CPF *</label>
						</span>
						
						<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
							<Dropdown className="w-full" value={state} optionLabel='name' optionValue='id' id='state' options={stateSelectItems} onChange={stateChange} placeholder=''/>
							<label htmlFor='state'>Estado *</label>
						</span>

						<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
							<Dropdown className="w-full" value={city} optionLabel='name' optionValue='id' id='city' options={citiesSelectItems} onChange={e => set_city(e.value)} placeholder=''/>
							<label htmlFor='city'>Cidade *</label>
						</span>

						<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
							<InputText required id='street' className="w-full" value={street} onChange={ev => set_street(ev.target.value)} />
							<label htmlFor='street'>Rua *</label>
						</span>

						<span className='p-float-label mt-5 m-2 w-12 md:w-2 inline-block'>
							<InputMask mask='?999999' required id='number' className="w-full" value={number} onChange={ev => set_number(ev.target.value)} />
							<label htmlFor='number'>Número *</label>
						</span>

						<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
							<InputMask mask='(99) 9 9999-9999' required id='phone' className="w-full" value={phone} onChange={ev => set_phone(ev.target.value)} />
							<label htmlFor='phone'>Telefone *</label>
						</span>

						<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
							<Dropdown className="w-full" value={type} optionLabel='name' optionValue='id' id='type' options={listTypes} onChange={e => set_type(e.value)} placeholder='Tipo'/>
							<label htmlFor='type' style={{ top: '-.75rem', fontSize: '12px' }}>Tipo *</label>
						</span>

						<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
							<Dropdown className="w-full" value={club} optionLabel='name' optionValue='id' id='club' options={listClubs} onChange={e => set_club(e.value)} placeholder='Atlética'/>
							<label htmlFor='club' style={{ top: '-.75rem', fontSize: '12px' }}>Atlética *</label>
						</span>

						<span className='p-float-label mt-5 m-2 w-12 md:w-5 inline-block'>
							<InputText required id='college' maxLength={100} className="w-full" value={college} onChange={ev => set_college(ev.target.value)} />
							<label htmlFor='college'>Instituição *</label>
						</span>

						<span className='p-float-label mt-5 m-2 w-12 md:w-5 inline-block'>
							<InputText required id='course' maxLength={100} className="w-full" value={course} onChange={ev => set_course(ev.target.value)} />
							<label htmlFor='course'>Curso *</label>
						</span>

						<span className='p-float-label mt-5 m-2 w-12 md:w-5 inline-block'>
							<Dropdown className="w-full" value={need_accommodation} optionLabel='need_accommodation' optionValue='id' id='type' options={[{id: false, need_accommodation: 'Não'}, {id: true, need_accommodation: 'Sim'}]} onChange={e => set_need_accommodation(e.value)} placeholder='Acomodação'/>
							<label htmlFor='need_accommodation' style={{ top: '-.75rem', fontSize: '12px' }}>Vai precisar de Alojamento? *</label>
						</span>

						<span className='p-float-label mt-5 m-2 w-12 inline-block'>
							<InputText required id='photo' type='file' onChange={ev => ev.target.files?.length && set_photo(ev.target.files[0])  } />
							<label htmlFor='photoLabel' style={{ top: '-.75rem', fontSize: '12px' }}>Foto de Rosto *</label>
							<br />
							<span className={styles.span_picture}> * Foto de frente, sem boné ou óculos escuro e em ambiente bem iluminado</span>
						</span>
						
						<span className='p-float-label mt-5 m-2 w-12 inline-block'>
							<InputText required={type != '3'} id='enroll' type='file' onChange={ev => ev.target.files?.length && set_enroll(ev.target.files[0])} />
							<label style={{ top: '-.75rem', fontSize: '12px' }} htmlFor='enroll'>{nomeAnexo()}</label>
						</span>

					</Card>
				</form>
			}
		</div>
	);
}
