import ProtectedMenu from 'components/ProtectedMenu';
import React, { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export default function ProtectedRoute(props : {children?: React.ReactElement}) {
	const user = localStorage.getItem('ITM_USR');
	
	if (!user) return <Navigate to="/login" replace/>;

	return props.children ? props.children : (
		<div>
			<ProtectedMenu />
			<Outlet />
		</div>
	);
}