import axios from 'api';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import { IUser } from 'types';

export default function ResetPassword() {


	const navigate = useNavigate();
	const toast = useRef<Toast>(null);

	const [email, setEmail] = useState('');
	const [rg, set_rg] = useState('');
	const [password, setPassword] = useState('');
	const [cpf, set_cpf] = useState('');
	const [allowUpdate, setAllowUpdate] = useState(false);
	const [user, setUser] = useState<IUser>();

	const registrar = (ev: React.FormEvent<HTMLFormElement>) => {
		ev.preventDefault();
		
		axios.post('user/resetPassword', {
			email,
			rg,
			cpf: cpf.replace(/\.|-/g, '')
		}).then(res => {
			toast.current?.show({severity: 'success', summary: 'Sucesso', detail: 'Por favor redefina a senha...'});
			setAllowUpdate(true);
			setUser(res.data.data);
		}).catch(err => {
			toast.current?.show({severity: 'error', summary: 'Falha', detail: 'Cadastro não encontrado...'});
		});
	};

	const update = (ev: React.FormEvent<HTMLFormElement>) => {
		ev.preventDefault();
		axios.patch('user/resetPassword', {
			id: user?.id,
			password
		}).then(res => {
			toast.current?.show({severity: 'success', summary: 'Sucesso', detail: 'Senha atualizada, realize o login...'});
			setTimeout(() => {
				navigate('/login');
			}, 1750);
		}).catch(err => {
			toast.current?.show({severity: 'error', summary: 'Falha', detail: 'Cadastro não encontrado...'});
		});
	};

	const footer = <span>
		<Button label='Confirmar' type='submit' icon='pi pi-check' style={{marginRight: '.25em'}}/>
		<Button label='Cancelar' type='button' onClick={() => navigate('/') } icon='pi pi-times' className='p-button-secondary mt-2'/>
	</span>;

	return (
		<div className='mx-4'>
			<Toast ref={toast} style={{opacity: 1}}></Toast>
			{!allowUpdate && <form onSubmit={registrar}>
				<Card title='Redefinir Senha' footer={footer} className='my-3'>
					
					<span className='p-float-label mt-5 m-2 w-12 md:w-4 inline-block'>
						<InputText required id='email' className='w-full' value={email} onChange={ev => setEmail(ev.target.value)} />
						<label htmlFor='email'>Email Cadastrado</label>
					</span>
					
					<span className='p-float-label mt-5 m-2 w-12 md:w-2 inline-block'>
						<InputMask mask='99999?99999999' required id='rg' className='w-full' value={rg} onChange={ev => set_rg(ev.target.value)} />
						<label htmlFor='rg'>RG</label>
					</span>

					<span className='p-float-label mt-5 m-2 w-12 md:w-2 inline-block'>
						<InputMask mask='999.999.999-99' required id='cpf' className='w-full' value={cpf} onChange={ev => set_cpf(ev.target.value)} />
						<label htmlFor='cpf'>CPF</label>
					</span>
				</Card>
			</form>}
			{allowUpdate && <form onSubmit={update}>
				<Card title='Redefinir Senha' footer={footer} className='my-3'>
					
					<span className='p-float-label mt-5 m-2 w-12 md:w-3 inline-block'>
						<Password required id='password' className="w-full" inputClassName='w-full' value={password} onChange={ev => setPassword(ev.target.value)} feedback={false} />
						<label htmlFor='password'>Nova Senha</label>
					</span>
				</Card>
			</form>}
		</div>
	);
}